/* eslint-disable camelcase */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import { useListContext, useStore } from 'react-admin';
import GPTFilterCheckBoxes from './GPTFilterCheckBoxes';
import { SHOW_FILTER_DRAWER_STORE } from './GPTsSidebar';
import { FilterOptionType, dropdownFilterStaticValues, featuredFilter } from './StaticFilters';

const GPTsFilters = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useStore(SHOW_FILTER_DRAWER_STORE.name, false);
  const { filterValues, setFilters, total, isFetching, isLoading } = useListContext();

  const toggleFilter = (turnOn: boolean, source: string, filterItemMaybeArray: string | string[]) => {
    //?.contains(featuredFilter.name) && !turnOn){
    setFilters(
      {
        ...filterValues,
        [source]: turnOn
          ? [
              ...(filterValues[source] || []),
              ...(Array.isArray(filterItemMaybeArray) ? filterItemMaybeArray : [filterItemMaybeArray]),
            ]
          : (filterValues[source] || []).filter((filterValToCheck: string) =>
              Array.isArray(filterItemMaybeArray)
                ? !filterItemMaybeArray.includes(filterValToCheck)
                : filterValToCheck !== filterItemMaybeArray
            ),
      },
      null,
      false
    );
  };

  return (
    <Box sx={{ backgroundColor: '#121212', height: '100%' }}>
      <Typography sx={{ p: { xs: '8px 4px 4px 16px' } }}>Explore the GPT store</Typography>
      <List component="nav" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <ListItem>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: 1, display: { md: 'none' } }}
            onClick={() => {
              setIsDrawerOpen(!isDrawerOpen);
            }}
            startIcon={<TuneIcon />}
          >
            {isDrawerOpen ? 'Hide' : 'Show'} filters
          </Button>
          {isFetching || isLoading ? <Typography>(Loading)</Typography> : <Typography>{total} GPTs found</Typography>}
        </ListItem>
        <ListItem>
          <ListItemText primary="Featured" />
          <Switch
            onChange={(event) => {
              toggleFilter(event.target.checked, featuredFilter.source, featuredFilter.name);
            }}
            edge="end"
            checked={filterValues?.[featuredFilter.source]?.includes(featuredFilter.name) || false}
          />
        </ListItem>
        <Divider />
        {dropdownFilterStaticValues.map((filter) => (
          <Accordion key={filter.label}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItemText primary={filter.label} />
            </AccordionSummary>
            <AccordionDetails>
              {/* <List sx={{ width: '100%', maxWidth: 360 }}> */}
              {/* TODO: get this to work fetching the latest */}
              {/* <ReferenceArrayInput source="bcg_added_cohorts@_has_keys_any" reference="bcg_allowed_cohorts">
                  <CheckboxGroupInput />
                </ReferenceArrayInput> */}
              {/* <FormGroup>
                {filter.id && filter.options && (
                  <CheckboxGroupInput
                    source={filter.id}
                    choices={filter.options}
                    optionValue="id"
                    optionText="label"
                    row={false}
                  />
                )} */}
              {filter.options.map((option: FilterOptionType) => (
                <GPTFilterCheckBoxes
                  checked={filterValues?.[filter.source]?.includes(option.id) || false}
                  key={option.id}
                  filterOption={option}
                  toggleFilter={toggleFilter}
                  filter={filter}
                />
              ))}
              {/* </FormGroup> */}
              {/* </List> */}
            </AccordionDetails>

            <Divider />
          </Accordion>
        ))}
      </List>
    </Box>
  );
};

export default GPTsFilters;

import { Admin, Authenticated, CustomRoutes, DataProvider, Resource } from 'react-admin';
import { BrowserRouter, Route } from 'react-router-dom';

import buildHasuraProvider from 'ra-data-hasura';
import { useEffect, useState } from 'react';
import { authProvider } from './authProvider';
import CustomLayout from './layouts/CustomLayout';
import AutoLogin from './pages/AutoLogin';
import FAQ from './pages/FAQ';
import GPTDetail from './pages/GPTDetail';
import GPTsGallery from './pages/GPTsGallery';
import { darkTheme, lightTheme } from './theme';
export const RESOURCE_NAME = 'GPTs';

export const App = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);

  useEffect(() => {
    const buildDataProvider = async () => {
      const hasuraDataProvider = await buildHasuraProvider({
        clientOptions: {
          uri: 'https://api-bcg-gpt-store.hasura.app/v1/graphql',
          headers: {
            ...(import.meta.env.VITE_HASURA_ADMIN_SECRET
              ? {
                  'x-hasura-admin-secret': import.meta.env.VITE_HASURA_ADMIN_SECRET || '',
                }
              : { 'X-Hasura-Role': import.meta.env.VITE_HASURA_ROLE || 'anonymous' }),
          },
        },
      });
      setDataProvider(hasuraDataProvider);
    };
    buildDataProvider();
  }, []);

  if (!dataProvider) return <p>Loading...</p>;

  return (
    <BrowserRouter>
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        theme={lightTheme}
        darkTheme={darkTheme}
        // layout={MainLayout}
        layout={CustomLayout}
      >
        <CustomRoutes>
          <Route
            path="/faq"
            element={
              <Authenticated>
                <FAQ />
              </Authenticated>
            }
          />
          <Route path="/auto-login" element={<AutoLogin />} />
        </CustomRoutes>
        <Resource name={RESOURCE_NAME} list={GPTsGallery} show={GPTDetail}>
          {/* <Route path=":gptId/show" element={<GPTDetail />} /> */}
        </Resource>
        {/* <CustomRoutes noLayout>
          <Route path="/" element={<Main />} />
        </CustomRoutes> */}
        {/* <Resource name="posts" list={ListGuesser} edit={EditGuesser} show={ShowGuesser} /> */}
        {/* <Resource name="comments" list={ListGuesser} edit={EditGuesser} show={ShowGuesser} /> */}
      </Admin>
    </BrowserRouter>
  );
};

/* eslint-disable camelcase */
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect } from 'react';
import { useListContext, useStore } from 'react-admin';
import { GPTType } from '../data-constants/data';
import ActiveFilterTag from './ActiveFilterTag';
import { FILTERS_TO_SHARE_STORE_NAME } from './FloatingChatButton';
import { SHOW_FILTER_DRAWER_STORE } from './GPTsSidebar';
import { GLOBAL_SEARCH_STORE_NAME } from './Header';
import { sortOptions } from './StaticFilters';
export const FIELD_FOR_GLOBAL_SEARCH = 'display_name,id,description,author_display_name,concat_all_categories@_ilike';

const GPTsSort = () => {
  const [globalSearchInput, setGlobalSearchInput] = useStore(GLOBAL_SEARCH_STORE_NAME);
  const { filterValues, setFilters, sort, setSort } = useListContext();
  const [isDrawerOpen, setIsDrawerOpen] = useStore(SHOW_FILTER_DRAWER_STORE.name, false);

  const [filtersStore, setFiltersStore] = useStore<{ filter?: Record<string, string | string[]> }>(
    FILTERS_TO_SHARE_STORE_NAME,
    {}
  );

  // console.log({ filterValues, setFilters, sort, rest });
  // Update global search input based on search input that is outside the context of the list
  useEffect(() => {
    if (filterValues[FIELD_FOR_GLOBAL_SEARCH] !== globalSearchInput) {
      setFilters?.(
        { ...filterValues, ...{ [FIELD_FOR_GLOBAL_SEARCH]: globalSearchInput || '' } } as Record<keyof GPTType, never>,
        null,
        true
      );
    }
  }, [globalSearchInput]);

  useEffect(() => {
    if (filtersStore?.filter) {
      setFilters?.(filtersStore.filter, null, false);
    }
  }, [filtersStore]);

  const clearFilters = () => {
    setFiltersStore({ filter: {} });
    setGlobalSearchInput('');
    setFilters?.({}, null, false);
  };

  const handleSortChange = (event: SelectChangeEvent<unknown>, filterEntry: (typeof sortOptions)[0]) => {
    setSort?.({
      field: (event.target.value as string) || '', // Cast event.target.value to string
      order:
        event.target.value === sort.field // if the same field is clicked, toggle the order
          ? sort.order === 'ASC'
            ? 'DESC'
            : 'ASC'
          : // else, set the new field to default order
            ((filterEntry.options.filter(
              (opt: { id: string; label: string; default?: string }) => opt.id === event.target.value
            )[0].default || 'ASC') as 'DESC' | 'ASC'),
    });
  };

  return (
    <Box sx={{ flexDirection: 'initial', ml: 0, paddingLeft: 0, width: '100%' }}>
      <Box sx={{ ml: 2, flexDirection: { xs: 'column-reverse', sm: 'row' }, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ mt: 0, mb: 2, flexDirection: 'row', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
          {Object.keys(filterValues || {}).map((filterName) => (
            <ActiveFilterTag key={filterValues[filterName]} source={filterName} />
          ))}
          {!!filterValues && Object.keys(filterValues).length !== 0 && (
            <Box>
              <Button sx={{ mt: 2 }} onClick={clearFilters}>
                Clear filters
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mr: 1, display: { md: 'none' } }}
            onClick={() => {
              setIsDrawerOpen(!isDrawerOpen);
            }}
            startIcon={<TuneIcon />}
          >
            {isDrawerOpen ? 'Hide' : 'Show'} filters
          </Button>
          {/* sort by */}
          <FormGroup sx={{ minWidth: 120, mb: 4 }}>
            <InputLabel id={`sort-by`}>{sortOptions.label}</InputLabel>
            <Select
              variant="outlined"
              labelId={`${sortOptions.id}-label`}
              id={sortOptions.id}
              sx={{ mr: 2 }}
              value={sort?.field || ''}
              onChange={(event) => {
                handleSortChange(event, sortOptions);
              }}
              label={sortOptions.label}
            >
              <MenuItem value=""> </MenuItem>
              {sortOptions.options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default GPTsSort;

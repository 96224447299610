import AddIcon from '@mui/icons-material/Add';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  ListItem,
  ListItemButton,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { useListContext } from 'react-admin';
import { FilterOptionType } from './StaticFilters';

const GPTFilterCheckBoxes = (props: {
  checked: boolean;
  filter: {
    type: string;
    label: string;
    source: string;
    options: FilterOptionType[];
  };
  toggleFilter: (turnOn: boolean, source: string, filterItem: string | string[]) => void;
  filterOption: FilterOptionType;
}) => {
  const { filter, toggleFilter, filterOption } = props;
  const [expandedChildren, setExpandedChildren] = useState<boolean>(false);
  const { filterValues } = useListContext();
  const hasChildren = !!filterOption.children?.length;

  return (
    <ListItem key={props.filterOption.id} disablePadding>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={
            hasChildren ? (
              <Tooltip title="click to view sub categories">
                <AddIcon />
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <ListItemButton
            role={undefined}
            dense
            onClick={(event) => {
              toggleFilter(
                !(filterValues?.[filter.source]?.includes(filterOption.id) || false),
                filter.source,
                hasChildren
                  ? [filterOption.id, ...(filterOption.children?.map((childOption) => childOption.id) || [])]
                  : filterOption.id
              );

              event.stopPropagation();
              event.preventDefault();
            }}
          >
            {/* <ListItemIcon> */}

            <FormControlLabel
              control={
                <Checkbox
                  edge="start"
                  checked={props.checked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': props.filterOption.label }}
                />
              }
              label={props.filterOption.label}
            />

            {/* </ListItemIcon> */}
            {/* <ListItemText id={props.filterOptions.id} primary={props.filterOptions.label} /> */}
          </ListItemButton>
          {/* {hasChildren && (
            <Button
              onClick={() => {
                setExpandedChildren(!expandedChildren);
              }}
            >
              {expandedChildren ? 'Hide' : 'Show sub functions'}
            </Button>
          )} */}
        </AccordionSummary>
        {hasChildren && (
          <AccordionDetails>
            {props.filterOption?.children?.map((option: FilterOptionType) => (
              <GPTFilterCheckBoxes
                checked={filterValues?.[filter.source]?.includes(option.id) || false}
                key={option.id}
                filter={filter}
                filterOption={{
                  label: option.label,
                  id: option.id,
                }}
                toggleFilter={toggleFilter}
              />
            ))}
          </AccordionDetails>
        )}
      </Accordion>
    </ListItem>
  );
};

export default GPTFilterCheckBoxes;

import { Box, Button, Card, CardActions, CardContent, Rating, Typography } from '@mui/material';
// import { Link as RouterLink, useCreatePath } from 'react-admin';
import { useCreatePath, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import React from 'react';
import { RESOURCE_NAME } from '../App';
import { GPTType } from '../data-constants/data';
import { DEFAULT_GPT_PROFILE_PICTURE_URL } from '../pages/GPTDetail';
import EllipsisTypography from './EllipsisTypography';

const GPTCard = (props: { record: GPTType }) => {
  const createPath = useCreatePath();
  const { record: gpt } = props;
  const navigate = useNavigate();
  const notify = useNotify();
  return (
    <Box
      onClick={() => {
        navigate(
          createPath({
            resource: RESOURCE_NAME,
            id: gpt.id,
            type: 'show',
          })
        );
      }}
      sx={{
        m: 2,
        cursor: 'pointer',
        p: 0,
        borderRadius: 4,
        textTransform: 'unset',
        textAlign: 'unset',
        background: 'none',
        transition: 'all 0.4s ease',
        '&:hover': { background: 'none' },
      }}
    >
      <Card
        sx={{
          height: 200,
          width: 200,
          maxWidth: 345,
          backgroundImage: `url(${gpt.profile_picture_url || DEFAULT_GPT_PROFILE_PICTURE_URL})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          // margin: '10px',
          borderRadius: 4,
          overflow: 'hidden',
          textTransform: 'unset',
          transition: 'all 0.4s ease',
          '&:hover': {
            '& .further-details': {
              display: 'block',
              transition: 'all 0.4s ease',
            },
          },
        }}
      >
        {/* <CardActionArea
        sx={{
          
        }}
      > */}
        <CardContent
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            color: 'white',
            // background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,.7), rgba(0,0,0,.4))',
            background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.8))',

            padding: '100px 8px 8px 8px',
            // maxHeight: '100%',

            top: 0,
            // bottom: 'unset',
            // height: '100%',
            pt: 6,
            maxWidth: '100%',
            '&:hover': {
              background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,.7), rgba(0,0,0,.4))',
            },
          }}
        >
          <Box sx={{ position: 'relative', overflow: 'hidden', maxWidth: '100%' }}>
            <EllipsisTypography
              variant="h6"
              component="h2"
              sx={{ lineHeight: '1.2', fontWeight: 'bolder', minHeight: '5ex' }}
              allowedLineHeight={2}
            >
              {gpt.display_name}
            </EllipsisTypography>
            {/* <Divider sx={{ border: 'solid rgba(255, 255, 255, 0.52) 1px' }} /> */}
            <EllipsisTypography
              variant="body1"
              component="p"
              sx={{ mt: 1, fontSize: '10pt', minHeight: '6ex' }}
              allowedLineHeight={2}
            >
              {gpt.description}
            </EllipsisTypography>
            {/* <Box className="further-details" sx={{ display: 'none', transition: 'display 0.5s ease' }}> */}
            <Box sx={{ mt: 2 }}>
              {gpt.rating ? (
                <Box sx={{ display: 'flex', alignContent: 'center' }}>
                  <Rating
                    precision={0.25}
                    value={gpt.rating || 0}
                    readOnly
                    sx={{
                      ml: 2,
                      '& .MuiRating-iconFilled': {
                        color: '#ff6d75 ',
                      },
                      '& .MuiRating-iconHover': {
                        color: '#ff3d47 ',
                      },
                    }}
                  />
                  <Typography component="span" color="primary" sx={{ fontWeight: 'bolder', ml: 1 }}>
                    {Math.round(gpt.rating * 10) / 10}
                  </Typography>
                </Box>
              ) : (
                <>
                  <EllipsisTypography color="primary" sx={{ fontStyle: 'italic' }} variant="body1" component="p">
                    No rating
                  </EllipsisTypography>
                  {/* <EllipsisTypography variant="body1" component="p">
                    {gpt.num_conversations} Conversations
                  </EllipsisTypography>
                  <LinearProgress
                    variant="determinate"
                    value={Math.min(((gpt.num_conversations || 0) * 100) / 300, 100)}
                  /> */}
                </>
              )}
              {/* 
              <Box className="further-details" sx={{ display: 'block', transition: 'all 0.5s ease' }}>
                Quick View
              </Box> */}
            </Box>
          </Box>
          {/* </Box> */}
        </CardContent>
        <CardActions
          className="further-details"
          sx={{
            transition: 'all 0.5s ease',
            display: 'none',
            backgroundColor: '#3EAD92',
            position: 'absolute',
            bottom: '0',
            p: 0,
            width: '100%',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            textAlign: 'center',
            fontWeight: 'bolder',
          }}
        >
          <Button
            fullWidth
            variant="text"
            sx={{ color: 'white' }}
            onClick={(ev: React.MouseEvent<HTMLButtonElement> | undefined) => {
              notify('Quick view coming soon');
              // ev?.preventDefault();
            }}
          >
            Quick View
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default GPTCard;

/* eslint-disable camelcase */
import { Box, Breakpoint, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { useStore } from 'react-admin';
import GPTsFilters from './GPTsFilters';

export const SHOW_FILTER_DRAWER_STORE = { name: 'ShowFilterDrawer', breakpoint: 'md' };

const GPTsSidebar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useStore(SHOW_FILTER_DRAWER_STORE.name, false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(SHOW_FILTER_DRAWER_STORE.breakpoint as Breakpoint));
  const isSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const drawerWidth = isSmaller ? 350 : 450;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
    if (open) document.getElementById('new-message-input')?.focus();
  };

  return (
    <Box
      sx={{
        flexDirection: 'initial',
        ml: 0,
        paddingLeft: 0,
        backgroundColor: 'black',
        width: isMobile ? (isDrawerOpen ? drawerWidth : 0) : drawerWidth,
      }}
    >
      {isMobile ? (
        <Drawer
          anchor="left"
          open={isMobile ? isDrawerOpen : true}
          onClose={toggleDrawer(false)}
          variant={isMobile ? 'temporary' : 'persistent'}
          // keepMounted
          sx={{
            width: isMobile ? (isDrawerOpen ? drawerWidth : 0) : drawerWidth,
            flexShrink: 0,
            backgroundColor: 'black',
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <GPTsFilters />
        </Drawer>
      ) : (
        <GPTsFilters />
      )}
    </Box>
  );
};

export default GPTsSidebar;

import { Box, Container, Typography } from '@mui/material';
import { List, Pagination, WithListContext, useListContext } from 'react-admin';
import GPTCard from '../components/GPTCard';
import GPTsSidebar from '../components/GPTsSidebar';
import GPTsSort from '../components/GPTsSort';
import { GPTType } from '../data-constants/data';
const PostPagination = () => (
  <Pagination
    sx={{ mr: 10 }}
    rowsPerPageOptions={[60, 100]}
    // limit={<Box sx={{ textAlign: 'center' }}>No GPTs found</Box>}
  />
);

const GPTsGallery = () => {
  const { isFetching, isLoading } = useListContext();
  return (
    <Container
      maxWidth="xl"
      sx={{
        m: 'auto',
        padding: '0 16px !important',
      }}
    >
      {/* <GPTsFilters /> */}
      <Box
        sx={{
          flexDirection: 'initial',
          ml: 0,
          paddingLeft: 0,
          '&>*>.RaList-main': {
            width: '100%',
          },
        }}
      >
        {/* <GPTsFilters /> */}
        <List
          perPage={60}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sort={{ field: 'rating,ratings_count,num_conversations', order: 'desc_nulls_last,desc,desc' as any }}
          actions={<GPTsSort></GPTsSort>}
          filterDefaultValues={{ 'bcg_added_categories_use_cases@_has_keys_any': ['★ Featured'] }}
          // filters={}
          empty={
            isFetching || isLoading ? (
              <Typography>(Loading)</Typography>
            ) : (
              <Box sx={{ textAlign: 'center' }}>No GPTs found</Box>
            )
          }
          emptyWhileLoading
          pagination={<PostPagination />}
          aside={<GPTsSidebar />}
          sx={{
            flexDirection: { xs: 'column-reverse', md: 'row-reverse' },
            '&>*>.MuiCard-root': {
              background: 'none',
            },
          }}
        >
          <WithListContext<GPTType>
            render={(listContext) => (
              <Box
                className="box"
                display="flex"
                justifyContent="start"
                alignItems="center"
                flexWrap="wrap"
                sx={{ maxWidth: '100%' }}
              >
                {listContext?.data?.length ? (
                  listContext?.data?.map((record) => <GPTCard key={record.id} record={record}></GPTCard>)
                ) : (
                  <Box sx={{ ml: 2 }}>
                    No GPTs found with this filter criteria, but new ones coming soon!
                    <br />
                    (Try removing filters you may have added)
                  </Box>
                )}
              </Box>
            )}
          />
        </List>
      </Box>
    </Container>
  );
};

export default GPTsGallery;

import { Components, createTheme } from '@mui/material/styles';
import { RaThemeOptions, defaultTheme } from 'react-admin';

export const lightTheme: RaThemeOptions = {
  ...defaultTheme,
  palette: {
    primary: {
      main: '#3EAD92',
    },
    secondary: {
      main: '#b0b0b0',
    },
  },
  typography: {
    fontFamily: `henderson-bcg-sans, "Helvetica Neue", Helvetica, Arial, sans-serif`,
  },
};
export const darkTheme: RaThemeOptions = {
  ...lightTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: '#3EAD92', // primary color from the design
      dark: '#3ead92cf', // darker shade of the primary color
      contrastText: '#fff', // text color that contrasts with the primary color
    },
    secondary: {
      main: '#ffffff', // secondary color from the design
      // darker: '#3ead92cf', // darker shade of the primary color
    },
  },
  components: {
    ...defaultTheme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          '& .MuiRating-iconFilled': {
            color: '#3EAD92 !important',
          },
          '& .MuiRating-iconHover': {
            color: '#3ead92cf !important',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {},
      },
    },
  } as Components,
};

export const muiTheme = createTheme({
  palette: {
    mode: 'dark', // dark mode is on
    primary: {
      main: '#3EAD92', // primary color from the design
      contrastText: '#fff', // text color that contrasts with the primary color
    },
    secondary: {
      main: '#3EAD92', // secondary color from the design
    },
  },
  //   components: {
  //     ...defaultTheme.components,
  //     MuiTextField: {
  //         defaultProps: {
  //             variant: 'outlined' as const,
  //         },
  //     },
  //     MuiFormControl: {
  //         defaultProps: {
  //             variant: 'outlined' as const,
  //         },
  //     },
  // },
});

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ClipboardIcon from '@mui/icons-material/ContentPasteGo';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';

import { useStore } from 'react-admin';

export const notificationTypes = {
  clipboard: {
    tooltip: 'Copy URL of this GPT to clipboard to share it via email or slack',
    text: 'Copied GPT URL to clipboard for sharing!',
    icon: <ClipboardIcon />,
  },
  bookmark: {
    tooltip:
      'Save this GPT as one of your favs (saves to local browser only). But when you open the GPT you can also choose to "pin to sidebar" to more easily access this GPT when in openai.com',
    text: 'Bookmark feature not yet available, sorry! ',
    icon: <BookmarkIcon />,
  },
};
const BookmarkButton = (
  props: IconButtonProps & {
    GPTID: string;
    name: string;
    notificationFunction: (text: string, icon: JSX.Element) => unknown;
  }
) => {
  const { GPTID, name, notificationFunction } = props;
  const [localGPTBookmarks, setLocalGPTBookmarks] = useStore<{ [name: string]: string }>('LocalGPTBookmarks', {});
  const toggleBookmark = (GPTID: string, name: string) => {
    if (localGPTBookmarks[GPTID]) {
      delete localGPTBookmarks[GPTID];
      setLocalGPTBookmarks({ ...localGPTBookmarks });
      notificationFunction('Removed bookmark locally for ' + name, notificationTypes['bookmark'].icon);
    } else {
      notificationFunction('Added bookmark locally for ' + name, notificationTypes['bookmark'].icon);
      setLocalGPTBookmarks({ ...localGPTBookmarks, [GPTID]: name });
    }
  };

  return (
    <Tooltip title={notificationTypes['bookmark'].tooltip}>
      <IconButton
        onClick={() => {
          toggleBookmark(GPTID, name);
        }}
      >
        {localGPTBookmarks[GPTID] ? <BookmarkIcon /> : <BookmarkBorderIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default BookmarkButton;

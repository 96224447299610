import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CustomizedEllipsisTypographyProps extends TypographyProps {
  allowedLineHeight?: number;
}

const EllipsisTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'allowedLineHeight',
})<CustomizedEllipsisTypographyProps>(({ theme, allowedLineHeight }) => ({
  display: '-webkit-box',
  WebkitLineClamp: allowedLineHeight || 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  // textOverflow: 'ellipsis',
  // whiteSpace: '',
}));

export default EllipsisTypography;

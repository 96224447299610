import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'; // Icon for the bot, just an example
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlined';
import PersonIcon from '@mui/icons-material/Person'; // Icon for the user
import SendIcon from '@mui/icons-material/Send';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Drawer,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  ThemeProvider,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { MuiMarkdown, getOverrides } from 'mui-markdown';
import React, { useEffect, useState } from 'react';
import { useStore } from 'react-admin';

// Define the theme
const chatTheme = createTheme({
  palette: {
    primary: {
      main: '#3EAD92',
    },
    secondary: { main: '#00A887' },
    background: {
      default: '#EAEAEA',
      paper: '#EAEAEA',
    },
  },
});

export const FILTERS_TO_SHARE_STORE_NAME = 'GPTs.FiltersToShare';
const FloatingChatButton = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmaller = useMediaQuery(theme.breakpoints.down('md'));

  const drawerWidth = isSmaller ? 350 : 450;
  const [isDrawerOpen, setIsDrawerOpen] = useStore('GPTChatDrawerOpen', !isMobile);

  const [chatDetailsStore, setChatDetailsStore] = useStore<{
    threadID?: string;
    messages: { id: string | number; text: string; role: string }[];
  }>('ChatDetails', { messages: [] });
  const [filtersStore, setFiltersStore] = useStore<{ filter?: Record<string, string | string[]> }>(
    FILTERS_TO_SHARE_STORE_NAME,
    {}
  );
  // const [messages, setMessages] = useState(shortMessageList);
  // const [messages, setMessages] = useState<{ id: string | number; text: string; role: string }[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState('');
  // const [chatDetailsStore.threadID, setchatDetailsStore.threadID] = useState('');

  useEffect(() => {
    if (isLoading) {
      const timer = setInterval(() => {
        setLoadingProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer);
            return 100;
          }
          const diff = Math.random() * 5;

          return Math.min(oldProgress + diff, 100);
        });
      }, 200); // 2000ms = 2s, so the progress bar will complete in approximately 20s

      return () => {
        clearInterval(timer);
      };
    }
  }, [isLoading]);
  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const messageToSend = newMessage;
      const newMessagesList = [
        ...chatDetailsStore.messages,
        { id: chatDetailsStore.messages.length + 1, text: newMessage, role: 'User' },
      ];
      setChatDetailsStore({ ...chatDetailsStore, messages: newMessagesList });
      setIsLoading(true);
      setLoadingProgress(10);
      setIsError('');

      const raw = JSON.stringify({
        messages: newMessagesList
          .reverse()
          .map((msg) => ({ role: msg.role.toLowerCase() === 'user' ? 'user' : 'assistant', content: msg.text })),
        ...(chatDetailsStore.threadID && { threadID: chatDetailsStore.threadID }),
      });
      setNewMessage('');

      // fetch('https://openai-existing-assistant-caller.glitch.me/assistant-message/send', {
      fetch(
        'https://api.retool.com/v1/workflows/3a0313e4-0e4f-4719-8ff3-ad99fe82dbe1/startTrigger?workflowApiKey=retool_wk_7ea09e09a8374ec59df5948b70432d5b',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: raw,
          redirect: 'follow',
        }
      )
        .then(async (response) => {
          const data = await response.json();
          const receivedMessage = (data?.messages?.[0].content?.[0]?.text?.value || '').replace('|', '-');
          // const receivedMessage = data?.message;
          // if(debugger){}
          setChatDetailsStore({
            ...chatDetailsStore,
            threadID: data.threadId,
            messages: [...newMessagesList, { id: data?.messages?.[0].id, text: receivedMessage, role: 'Bot' }],
          });
          // check for suggestions
          const urls: string[] =
            receivedMessage
              ?.match(/\[(.*?)\]\((https:\/\/chat.openai.com\/g\/[^ )]+)/g)
              ?.map((match: string) => match.slice(match.indexOf('(') + 1)) || [];
          if (urls.length > 0) {
            // if (urls.length > 1) {
            setFiltersStore({
              ...filtersStore,
              // eslint-disable-next-line camelcase
              filter: { short_url: urls.map((url) => url.replace('https://chat.openai.com/g/', '')) },
            });
            // } else {
            // navigate(`/GPTs/g-${urls[0].split('-')[1]}/show`);
            // console.log(`/GPTs/g-${urls[0].split('-')[1]}/show`);
            // }
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setNewMessage(messageToSend);
          setIsError(error);
          console.error(error);
        });
    }
  };
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
    if (open) document.getElementById('new-message-input')?.focus();
  };
  return (
    <>
      <Tooltip title="Use chat to help find the perfect GPT">
        <Fab
          color="primary"
          aria-label="chat"
          style={{ position: 'fixed', bottom: '24px', right: '24px' }}
          onClick={toggleDrawer(true)}
        >
          <AutoAwesomeIcon />
        </Fab>
      </Tooltip>

      <ThemeProvider theme={chatTheme}>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          variant={isMobile ? 'temporary' : 'persistent'}
          // keepMounted
          sx={{
            width: isDrawerOpen ? drawerWidth : 0,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Box
            sx={{
              // width: drawerWidth - 20,
              flexGrow: 1,
            }}
            role="presentation"
            // onClick={toggleDrawer(false)}
          >
            {/* Content of drawer goes here */}
            <Box
              sx={{
                height: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ListItem
                sx={{
                  pt: isMobile ? 2 : isSmaller ? 25 : 20,
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#EAEAEA',
                  display: 'flex',
                  zIndex: (theme) => theme.zIndex.appBar + 1,
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                <ListItemText primary="Ask ChatGPT...about GPTs!" />
                <Tooltip title="Clear chat and start fresh">
                  <IconButton
                    onClick={() => {
                      setChatDetailsStore({ messages: [] });
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close the chat box">
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
              <Divider />
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                <Box
                  className="message-list"
                  sx={{ flexGrow: 1 }}
                  onClick={() => {
                    document.getElementById('new-message-input')?.focus();
                  }}
                >
                  <List>
                    {chatDetailsStore.messages.map((message, index) => (
                      <ListItem
                        sx={{ alignItems: 'start' }}
                        key={index}
                        autoFocus={index === chatDetailsStore.messages.length - 1}
                      >
                        {message.role === 'User' ? (
                          <>
                            <ListItemText
                              sx={{
                                backgroundColor: '#D8D8D8',
                                borderRadius: '10px',
                                padding: '6px 10px',
                                margin: '5px 0',
                                '& span': {
                                  // targeting the span inside the ListItemText
                                  wordBreak: 'break-word', // Ensure long words do not overflow
                                },
                              }}
                            >
                              <MuiMarkdown
                                options={{
                                  overrides: {
                                    ...getOverrides(),
                                    // overrides={{
                                    aa: { props: { target: '_blank' } },
                                    a: { props: { target: '_blank' } },
                                    link: { props: { target: '_blank' } },
                                    Link: { props: { target: '_blank' } },
                                    p: { component: 'div' },
                                  },
                                }}
                              >
                                {message.text}
                              </MuiMarkdown>
                            </ListItemText>
                            <ListItemAvatar>
                              <Avatar sx={{ ml: 2 }}>
                                <PersonIcon />
                              </Avatar>
                            </ListItemAvatar>
                          </>
                        ) : (
                          <>
                            <ListItemAvatar>
                              <Avatar sx={{ mr: 2, backgroundColor: '#3EAD92' }}>
                                <AutoAwesomeIcon sx={{ backgroundColor: '#3EAD92', color: 'white' }} />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              sx={{
                                backgroundColor: 'rgba(62, 173, 146, 0.15)',
                                borderRadius: 2,
                                padding: '10px 6px',
                                margin: '5px 0',
                                '& span': {
                                  // targeting the span inside the ListItemText
                                  wordBreak: 'break-word', // Ensure long words do not overflow
                                },
                              }}
                            >
                              <MuiMarkdown
                                options={{
                                  overrides: {
                                    ...getOverrides(),
                                    // overrides={{
                                    aa: { props: { target: '_blank' } },
                                    a: { props: { target: '_blank' } },
                                    link: { props: { target: '_blank' } },
                                    Link: { props: { target: '_blank' } },
                                    p: { component: 'div' },
                                  },
                                }}
                              >
                                {message.text}
                              </MuiMarkdown>
                            </ListItemText>
                          </>
                        )}
                      </ListItem>
                    ))}
                    {isError && (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar sx={{ mr: 2, backgroundColor: '#3EAD92' }}>
                            <SmartToyIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            backgroundColor: 'rgba(62, 173, 146, 0.05)',
                            borderRadius: 2,
                            padding: '10px 6px',
                            margin: '5px 0',
                            '& span': {
                              // targeting the span inside the ListItemText
                              wordBreak: 'break-word', // Ensure long words do not overflow
                            },
                          }}
                        >
                          <MuiMarkdown>There was an error reaching out to our GPT Bot. Try again?</MuiMarkdown>
                        </ListItemText>
                      </ListItem>
                    )}
                    {isLoading && (
                      <ListItem>
                        <ListItemAvatar>
                          <CircularProgress
                            variant={loadingProgress < 100 ? 'determinate' : 'indeterminate'}
                            value={loadingProgress}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            backgroundColor: 'rgba(62, 173, 146, 0.05)',
                            borderRadius: 2,
                            padding: '10px 6px',
                            margin: '5px 0',
                            '& span': {
                              // targeting the span inside the ListItemText
                              wordBreak: 'break-word', // Ensure long words do not overflow
                            },
                          }}
                        >
                          <MuiMarkdown>Thinking...</MuiMarkdown>
                        </ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '5px 5px 25px 15px',
                  backgroundColor: '#EAEAEA',
                  position: 'sticky',
                  bottom: 0,
                }}
                className="new-message-box"
              >
                <TextField
                  id="new-message-input"
                  fullWidth
                  size="small"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                  inputProps={{ sx: { py: 1.5, px: 1.5 } }}
                  variant="outlined"
                  placeholder={isLoading ? 'Processing, beep boop beep' : '✧* Help me find a custom GPT for ...'}
                />
                <IconButton color="primary" onClick={handleSendMessage} disabled={isLoading}>
                  <SendIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </ThemeProvider>
    </>
  );
};

export default FloatingChatButton;

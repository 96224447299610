import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Typography } from '@mui/material';
import PageDetailLayout from '../layouts/PageDetail';
const faqs = [
  {
    question: 'What is a GPT?',
    answer: (
      <Box>
        A custom GPT is a way to provide a wrapper of context and detail, which then uses the Open AI GPT model to
        provide detailed answers. GPTs can be customized for a variety of tasks such as slide writing, client market
        research, question-answering, and content creation. To learn more about GPTs, you can visit <br />
        <Link href="https://openai.com/blog/introducing-gpts" target="_blank">
          OpenAI&apos;s Introduction to GPTs
        </Link>
        .
      </Box>
    ),
  },
  {
    question: 'How do I create a GPT?',
    answer: (
      <Box>
        Creating a GPT involves training a machine learning model on a specific dataset. You can start by collecting
        data relevant to the task you want the GPT to perform. For a step-by-step guide, visit <br />
        <Link href="https://help.openai.com/en/articles/8554397-creating-a-gpt" target="_blank">
          OpenAI's Guide on Creating a GPT
        </Link>
        .
      </Box>
    ),
  },
  {
    question: 'Will my GPT be public?',
    answer: (
      <Box>
        No, GPTs are private by default, and you can make them visible within the BCG organization, or share out
        individually to other BCG colleagues with a private link, much like a Google Doc or SharePoint file.
      </Box>
    ),
  },

  {
    question: 'Contact us',
    answer: (
      <Box>
        <p>This is a proof of concept for WWOM that may turn into a larger project.</p>
        <p>
          {' '}
          Open AI also has{' '}
          <Link href="https://chat.openai.com/gpts" target="_blank">
            their own way to browse GPTs
          </Link>{' '}
          that may become more in-depth and not require a custom app such as this in the future.
        </p>
        <p>
          Made with ♥ by BCG X (development: Max Struever with support from Ashwathy Mohan Menon; designs by Norah
          Zagorski). Project created by Patricia Faur with support from Liyuan Gao.
        </p>
        <p>
          {' '}
          Questions? Email{' '}
          <Link href="mailto:bcg-gpt-store@bcg.com" target="_blank">
            bcg-gpt-store@bcg.com
          </Link>
        </p>
      </Box>
    ),
  },
];
const FAQ = () => {
  return (
    <PageDetailLayout>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box>
          <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
            Frequently Asked Questions
          </Typography>
          {faqs.map((faq, index) => (
            <Accordion disableGutters sx={{ backgroundColor: '#121212', boxShadow: 'none' }} key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: '#121212' }}>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Box></Box>
      </Box>
    </PageDetailLayout>
  );
};

export default FAQ;

import { Chip } from '@mui/material';
import { useListContext, useStore } from 'react-admin';

import { FIELD_FOR_GLOBAL_SEARCH } from './GPTsSort';
import { GLOBAL_SEARCH_STORE_NAME } from './Header';

const ActiveFilterTag = (props: { source: string }) => {
  const { source } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [globalSearchInput, setGlobalSearchInput] = useStore(GLOBAL_SEARCH_STORE_NAME);
  const { filterValues, setFilters } = useListContext();
  // const [isActive, setIsActive] = useState(false);
  return Array.isArray(filterValues[source]) ? (
    filterValues[source].map((filterValueItem: string) => (
      <Chip
        key={filterValueItem}
        color="primary"
        sx={{ textTransform: 'capitalize', mr: 1, mt: 2 }}
        label={filterValueItem}
        onDelete={() => {
          const newFilterValues = {
            ...filterValues,
            [source]: filterValues?.[source]?.filter((item: string) => item !== filterValueItem),
          };
          setFilters?.(newFilterValues, null, false);
        }}
      />
    ))
  ) : (
    <Chip
      color="primary"
      sx={{ textTransform: 'capitalize', mr: 1, mt: 2 }}
      label={filterValues[source]}
      onDelete={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [source]: _, ...newFilterValues } = filterValues;
        if (source === FIELD_FOR_GLOBAL_SEARCH) {
          setGlobalSearchInput('');
        }
        setFilters?.(newFilterValues, null, false);
      }}
    />
  );
};

export default ActiveFilterTag;

import { useAuthenticated, useLogin } from 'react-admin';
import { useLocation } from 'react-router-dom';
import PageDetailLayout from '../layouts/PageDetail';
const AutoLogin = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const login = queryParams.get('login');
  const loginRedirect = useLogin();
  try {
    if (login) {
      const loginDetails = JSON.parse(atob(login));
      if (loginDetails.username && loginDetails.password) {
        loginRedirect({ username: loginDetails.username, password: loginDetails.password });
      }
    }
  } catch {
    useAuthenticated();
  }
  return <PageDetailLayout>Loading...</PageDetailLayout>;
};

export default AutoLogin;

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, BoxProps, Button, Container } from '@mui/material';
import { useCreatePath } from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';

const PageDetailLayout = ({ children }: BoxProps) => {
  const createPath = useCreatePath();

  return (
    <>
      <Box>
        <Button
          sx={{ textTransform: 'unset', color: 'white' }}
          component={RouterLink}
          to={createPath({
            resource: 'GPTs',
            type: 'list',
          })}
          startIcon={<ArrowBackIosNewIcon />}
        >
          Back
        </Button>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      <Container
        sx={{
          m: 0,
          padding: '0 16px !important',
          flexGrow: 1,
          margin: 'auto',
        }}
      >
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Box>{children}</Box>
        </Box>
      </Container>
    </>
  );
};

export default PageDetailLayout;

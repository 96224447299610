export const featuredFilter = {
  source: 'bcg_added_categories_use_cases@_has_keys_any',
  name: '★ Featured',
};

export type FilterOptionType = {
  label: string;
  id: string;
  parent?: string;
  children?: { label: string; id: string; parent?: string }[];
};
export const dropdownFilterStaticValues: {
  type: string;
  label: string;
  source: string;
  options: FilterOptionType[];
}[] = [
  {
    type: 'filter',
    label: 'Cohort',
    source: 'bcg_added_cohorts@_has_keys_any',
    options: [
      {
        label: 'All',
        id: 'All',
      },
      {
        label: 'A-C',
        id: 'A-C',
      },
      {
        label: 'PL',
        id: 'PL',
      },
      {
        label: 'PR/P',
        id: 'PR/P',
      },
      {
        label: 'MDP',
        id: 'MDP',
      },
      {
        label: 'BST',
        id: 'BST',
      },
      {
        label: 'BCG. X',
        id: 'BCG. X',
      },
      {
        label: 'Specialty Business',
        id: 'Specialty Busines',
      },
      {
        label: 'Knowledge Team',
        id: 'Knowledge Team',
      },
    ],
  },
  {
    type: 'filter',
    label: 'Categories / use cases',
    source: 'bcg_added_categories_use_cases@_has_keys_any',
    options: [
      {
        label: '★ Featured',
        id: '★ Featured',
      },
      {
        label: 'Research',
        id: 'Research',
      },
      {
        label: 'Writing/Translating',
        id: 'Writing/Translating',
      },
      {
        label: 'Slide making',
        id: 'Slide making',
      },
      {
        label: 'Email Writing',
        id: 'Writing',
      },
      {
        label: 'Data analysis/Visualization',
        id: 'Data analysis/Visualization',
      },
      {
        label: 'Coding',
        id: 'Coding',
      },
      {
        label: 'Proposal help',
        id: 'Proposal help',
      },
      {
        label: 'Image generator',
        id: 'Image generator',
      },
      {
        label: 'BCG Internal',
        id: 'BCG Internal',
      },
    ],
  },
  {
    type: 'filter',
    label: 'Industry',
    source: 'bcg_added_industries@_has_keys_any',
    options: [
      {
        label: 'BCG Transform',
        id: 'BCG Transform',
        children: [
          {
            label: 'Business Transformation',
            id: 'Business Transformation',
            parent: 'BCG Transform',
          },
          {
            label: 'Special Situation',
            id: 'Special Situation',
            parent: 'BCG Transform',
          },
        ],
      },
      {
        label: 'Consumer',
        id: 'Consumer',
        children: [
          {
            label: 'Consumer Products',
            id: 'Consumer Products',
            parent: 'Consumer',
          },
          {
            label: 'Fashion and Luxury',
            id: 'Fashion and Luxury',
            parent: 'Consumer',
          },
          {
            label: 'Retail',
            id: 'Retail',
            parent: 'Consumer',
          },
        ],
      },
      {
        label: 'Energy',
        id: 'Energy',
        children: [
          {
            label: 'Integrated Energy Transformation',
            id: 'Integrated Energy Transformation',
            parent: 'Energy',
          },
          {
            label: 'Low Carbon Energy & Infrastructure',
            id: 'Low Carbon Energy & Infrastructure',
            parent: 'Energy',
          },
          {
            label: 'Hot Topics',
            id: 'Hot Topics',
            parent: 'Energy',
          },
          {
            label: 'Climate, Sustainability and Energy Transition',
            id: 'Climate, Sustainability and Energy Transition',
            parent: 'Energy',
          },
          {
            label: 'Center for Energy Impact',
            id: 'Center for Energy Impact',
            parent: 'Energy',
          },
          {
            label: 'Digital and AI in Energy',
            id: 'Digital and AI in Energy',
            parent: 'Energy',
          },
          {
            label: 'Functional Accelerators & Incubation Squads',
            id: 'Functional Accelerators & Incubation Squads',
            parent: 'Energy',
          },
        ],
      },
      {
        label: 'Financial Institutions',
        id: 'Financial Institutions',
        children: [
          {
            label: 'Banks',
            id: 'Banks',
            parent: 'Financial Institutions',
          },
          {
            label: 'Payments and Fintechs',
            id: 'Payments and Fintechs',
            parent: 'Financial Institutions',
          },
          {
            label: 'Global Asset Managers',
            id: 'Global Asset Managers',
            parent: 'Financial Institutions',
          },
          {
            label: 'BCG Transform in FI',
            id: 'BCG Transform in FI',
            parent: 'Financial Institutions',
          },
          {
            label: 'Tech in FI',
            id: 'Tech in FI',
            parent: 'Financial Institutions',
          },
          {
            label: 'OPS in FI',
            id: 'OPS in FI',
            parent: 'Financial Institutions',
          },
          {
            label: 'Sustainable Finance and Investing',
            id: 'Sustainable Finance and Investing',
            parent: 'Financial Institutions',
          },
          {
            label: 'MSP in FI',
            id: 'MSP in FI',
            parent: 'Financial Institutions',
          },
          {
            label: 'POP in FI',
            id: 'POP in FI',
            parent: 'Financial Institutions',
          },
          {
            label: 'CFS in FI',
            id: 'CFS in FI',
            parent: 'Financial Institutions',
          },
          {
            label: 'Risk and Compliance in FI',
            id: 'Risk and Compliance in FI',
            parent: 'Financial Institutions',
          },
          {
            label: 'BCG X & FI',
            id: 'BCG X & FI',
            parent: 'Financial Institutions',
          },
        ],
      },
      {
        label: 'Health Care',
        id: 'Health Care',
        children: [
          {
            label: 'Biopharma',
            id: 'Biopharma',
            parent: 'Health Care',
          },
          {
            label: 'ESG in Health Care',
            id: 'ESG in Health Care',
            parent: 'Health Care',
          },
          {
            label: 'Oncology',
            id: 'Oncology',
            parent: 'Health Care',
          },
          {
            label: 'Payers, Providers, Systems and Services',
            id: 'Payers, Providers, Systems and Services',
            parent: 'Health Care',
          },
          {
            label: 'Medical Technologies',
            id: 'Medical Technologies',
            parent: 'Health Care',
          },
          {
            label: 'CFS & PMI in Health Care',
            id: 'CFS & PMI in Health Care',
            parent: 'Health Care',
          },
          {
            label: 'Health Care External Data Sources Hub',
            id: 'Health Care External Data Sources Hub',
            parent: 'Health Care',
          },
          {
            label: 'TDA in Health Care',
            id: 'TDA in Health Care',
            parent: 'Health Care',
          },
          {
            label: 'Global Health',
            id: 'Global Health',
            parent: 'Health Care',
          },
        ],
      },
      {
        label: 'Industrial Goods',
        id: 'Industrial Goods',
        children: [
          {
            label: 'Automotive & Mobility',
            id: 'Automotive & Mobility',
            parent: 'Industrial Goods',
          },
          {
            label: 'Greentech & Engineered Products (GEP)',
            id: 'Greentech & Engineered Products (GEP)',
            parent: 'Industrial Goods',
          },
          {
            label: 'Materials & Process Industries',
            id: 'Materials & Process Industries',
            parent: 'Industrial Goods',
          },
          {
            label: 'Digital in Industrial Goods',
            id: 'Digital in Industrial Goods',
            parent: 'Industrial Goods',
          },
          {
            label: 'BCG Transform in IG',
            id: 'BCG Transform in IG',
            parent: 'Industrial Goods',
          },
        ],
      },
      {
        label: 'Insurance',
        id: 'Insurance',
        children: [
          {
            label: 'Life and Health Insurance',
            id: 'Life and Health Insurance',
            parent: 'Insurance',
          },
          {
            label: 'P&C and Commercial Insurance',
            id: 'P&C and Commercial Insurance',
            parent: 'Insurance',
          },
          {
            label: 'New Digital Business Models',
            id: 'New Digital Business Models',
            parent: 'Insurance',
          },
          {
            label: 'Customer Journeys and Agile',
            id: 'Customer Journeys and Agile',
            parent: 'Insurance',
          },
          {
            label: 'Data & Analytics',
            id: 'Data & Analytics',
            parent: 'Insurance',
          },
          {
            label: 'TDA in Insurance',
            id: 'TDA in Insurance',
            parent: 'Insurance',
          },
          {
            label: 'Finance and Risk',
            id: 'Finance and Risk',
            parent: 'Insurance',
          },
          {
            label: 'Org Transformation',
            id: 'Org Transformation',
            parent: 'Insurance',
          },
          {
            label: 'Investments in Insurance',
            id: 'Investments in Insurance',
            parent: 'Insurance',
          },
          {
            label: 'BCG Transform / PMI',
            id: 'BCG Transform / PMI',
            parent: 'Insurance',
          },
          {
            label: 'Distribution-X',
            id: 'Distribution-X',
            parent: 'Insurance',
          },
          {
            label: 'Marketing, Sales & Pricing in Insurance',
            id: 'Marketing, Sales & Pricing in Insurance',
            parent: 'Insurance',
          },
          {
            label: 'Climate & Sustainability in Insurance',
            id: 'Climate & Sustainability in Insurance',
            parent: 'Insurance',
          },
        ],
      },
      {
        label: 'Principal Investors & Private Equity',
        id: 'Principal Investors & Private Equity',
        children: [
          {
            label: 'Transaction Services',
            id: 'Transaction Services',
            parent: 'Principal Investors & Private Equity',
          },
          {
            label: 'Portfolio Acceleration',
            id: 'Portfolio Acceleration',
            parent: 'Principal Investors & Private Equity',
          },
          {
            label: 'Fund Strategy & Operations',
            id: 'Fund Strategy & Operations',
            parent: 'Principal Investors & Private Equity',
          },
          {
            label: 'Private Equity',
            id: 'Private Equity',
            parent: 'Principal Investors & Private Equity',
          },
          {
            label: 'Infrastructure Investors & Real Estate',
            id: 'Infrastructure Investors & Real Estate',
            parent: 'Principal Investors & Private Equity',
          },
          {
            label: 'Principal Investors & Global Asset Managers',
            id: 'Principal Investors & Global Asset Managers',
            parent: 'Principal Investors & Private Equity',
          },
          {
            label: 'Tech Capital',
            id: 'Tech Capital',
            parent: 'Principal Investors & Private Equity',
          },
          {
            label: 'Digital & AI in PIPE',
            id: 'Digital & AI in PIPE',
            parent: 'Principal Investors & Private Equity',
          },
          {
            label: 'ESG in PIPE',
            id: 'ESG in PIPE',
            parent: 'Principal Investors & Private Equity',
          },
          {
            label: 'PIPE Ringfence & Training',
            id: 'PIPE Ringfence & Training',
            parent: 'Principal Investors & Private Equity',
          },
        ],
      },
      {
        label: 'Public Sector',
        id: 'Public Sector',
        children: [
          {
            label: 'Public Sector COVID-19 Response',
            id: 'Public Sector COVID-19 Response',
            parent: 'Public Sector',
          },
          {
            label: 'Defense & Security',
            id: 'Defense & Security',
            parent: 'Public Sector',
          },
          {
            label: 'Economic Development, Government Finances, Center of Government',
            id: 'Economic Development, Government Finances, Center of Government',
            parent: 'Public Sector',
          },
          {
            label: 'Education, Employment and Welfare (EEW)',
            id: 'Education, Employment and Welfare (EEW)',
            parent: 'Public Sector',
          },
          {
            label: 'Health Care Systems',
            id: 'Health Care Systems',
            parent: 'Public Sector',
          },
          {
            label: 'Operations and Cost in the Public Sector',
            id: 'Operations and Cost in the Public Sector',
            parent: 'Public Sector',
          },
          {
            label: 'People and Organization in the Public Sector',
            id: 'People and Organization in the Public Sector',
            parent: 'Public Sector',
          },
          {
            label: 'Transformation and Large Scale Change in PS',
            id: 'Transformation and Large Scale Change in PS',
            parent: 'Public Sector',
          },
          {
            label: 'Climate Action',
            id: 'Climate Action',
            parent: 'Public Sector',
          },
          {
            label: 'BCG Center for Digital Government',
            id: 'BCG Center for Digital Government',
            parent: 'Public Sector',
          },
          {
            label: 'Public Sector TCI',
            id: 'Public Sector TCI',
            parent: 'Public Sector',
          },
        ],
      },
      {
        label: 'Technology, Media & Telecom',
        id: 'Technology, Media & Telecom',
        children: [
          {
            label: 'Technology',
            id: 'Technology',
            parent: 'Technology, Media & Telecom',
          },
          {
            label: 'Media',
            id: 'Media',
            parent: 'Technology, Media & Telecom',
          },
          {
            label: 'Telecommunications',
            id: 'Telecommunications',
            parent: 'Technology, Media & Telecom',
          },
          {
            label: 'Gen AI x TMT',
            id: 'Gen AI x TMT',
            parent: 'Technology, Media & Telecom',
          },
        ],
      },
      {
        label: 'Travel, Cities and Infrastructure ',
        id: 'Travel, Cities and Infrastructure ',
        children: [
          {
            label: 'Logistics, Postal & Shipping',
            id: 'Logistics, Postal & Shipping',
            parent: 'Travel, Cities and Infrastructure ',
          },
          {
            label: 'Travel, Transport Infrastructure & Leisure',
            id: 'Travel, Transport Infrastructure & Leisure',
            parent: 'Travel, Cities and Infrastructure ',
          },
          {
            label: 'Cities, Infrastructure Development, Real Estate & Tourism',
            id: 'Cities, Infrastructure Development, Real Estate & Tourism',
            parent: 'Travel, Cities and Infrastructure ',
          },
          {
            label: 'TCI Big Rocks: Priority Development Topics',
            id: 'TCI Big Rocks: Priority Development Topics',
            parent: 'Travel, Cities and Infrastructure ',
          },
        ],
      },
    ],
  },

  {
    type: 'filter',
    label: 'Functional Practice Area',
    source: 'bcg_added_functional_practice_areas@_has_keys_any',
    options: [
      {
        label: 'Climate & Sustainability',
        id: 'Climate & Sustainability',
        children: [
          {
            label: 'Center for C&S Policy and Regulation',
            id: 'Center for C&S Policy and Regulation',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Decarbonization Solutions',
            id: 'Decarbonization Solutions',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Net Zero Strategy & Sustainable Operations',
            id: 'Net Zero Strategy & Sustainable Operations',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Circular Economy',
            id: 'Circular Economy',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Nature, Agriculture & Food',
            id: 'Nature, Agriculture & Food',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Sustainable Finance & Investing',
            id: 'Sustainable Finance & Investing',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'ESG Strategy',
            id: 'ESG Strategy',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'C&S x FPAs',
            id: 'C&S x FPAs',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Green Building Tech and Renewable Heat',
            id: 'Green Building Tech and Renewable Heat',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Low-carbon Hydrogen',
            id: 'Low-carbon Hydrogen',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Bioenergy',
            id: 'Bioenergy',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'EV in Energy and EV charging',
            id: 'EV in Energy and EV charging',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Renewables',
            id: 'Renewables',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Carbon Capture, Utilisation & Storage',
            id: 'Carbon Capture, Utilisation & Storage',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Offsetting & Commercial carbon management',
            id: 'Offsetting & Commercial carbon management',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Renewables Auctions & Tenders',
            id: 'Renewables Auctions & Tenders',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Geothermal',
            id: 'Geothermal',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Solar',
            id: 'Solar',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Wind',
            id: 'Wind',
            parent: 'Climate & Sustainability',
          },
          {
            label: 'Electrification',
            id: 'Electrification',
            parent: 'Climate & Sustainability',
          },
        ],
      },
      {
        label: 'Corporate Finance & Strategy',
        id: 'Corporate Finance & Strategy',
        children: [
          {
            label: 'Strategy',
            id: 'Strategy',
            parent: 'Corporate Finance & Strategy',
          },
          {
            label: 'Corporate Finance',
            id: 'Corporate Finance',
            parent: 'Corporate Finance & Strategy',
          },
          {
            label: 'Transactions and Integrations',
            id: 'Transactions and Integrations',
            parent: 'Corporate Finance & Strategy',
          },
          {
            label: 'CFS x CS',
            id: 'CFS x CS',
            parent: 'Corporate Finance & Strategy',
          },
        ],
      },
      {
        label: 'Global Advantage',
        id: 'Global Advantage',
        children: [
          {
            label: 'Center for Customer Insight (GA and MSP)',
            id: 'Center for Customer Insight (GA and MSP)',
            parent: 'Global Advantage',
          },
          {
            label: 'Economic Advantage of Nations',
            id: 'Economic Advantage of Nations',
            parent: 'Global Advantage',
          },
          {
            label: 'Emerging Market Competition',
            id: 'Emerging Market Competition',
            parent: 'Global Advantage',
          },
          {
            label: 'ESG in Emerging Markets',
            id: 'ESG in Emerging Markets',
            parent: 'Global Advantage',
          },
          {
            label: 'Fractal Advantage',
            id: 'Fractal Advantage',
            parent: 'Global Advantage',
          },
          {
            label: 'Geopolitics and Trade Impact',
            id: 'Geopolitics and Trade Impact',
            parent: 'Global Advantage',
          },
          {
            label: 'JVs, Alliances and Digital Ecosystems',
            id: 'JVs, Alliances and Digital Ecosystems',
            parent: 'Global Advantage',
          },
          {
            label: 'Market Acceleration',
            id: 'Market Acceleration',
            parent: 'Global Advantage',
          },
          {
            label: 'Navigating the New Globalization',
            id: 'Navigating the New Globalization',
            parent: 'Global Advantage',
          },
          {
            label: 'Priority Regional Insights',
            id: 'Priority Regional Insights',
            parent: 'Global Advantage',
          },
        ],
      },
      {
        label: 'Marketing, Sales & Pricing',
        id: 'Marketing, Sales & Pricing',
        children: [
          {
            label: 'Customer Demand & Innovation (incl CCI)',
            id: 'Customer Demand & Innovation (incl CCI)',
            parent: 'Marketing, Sales & Pricing',
          },
          {
            label: 'Marketing',
            id: 'Marketing',
            parent: 'Marketing, Sales & Pricing',
          },
          {
            label: 'Pricing',
            id: 'Pricing',
            parent: 'Marketing, Sales & Pricing',
          },
          {
            label: 'Sales',
            id: 'Sales',
            parent: 'Marketing, Sales & Pricing',
          },
          {
            label: 'Customer Experience',
            id: 'Customer Experience',
            parent: 'Marketing, Sales & Pricing',
          },
          {
            label: 'Mainstream Green: Customer Centric Sustainability & Innovation',
            id: 'Mainstream Green: Customer Centric Sustainability & Innovation',
            parent: 'Marketing, Sales & Pricing',
          },
          {
            label: 'GenAI in MSP',
            id: 'GenAI in MSP',
            parent: 'Marketing, Sales & Pricing',
          },
          {
            label: 'MSP Arsenal & Integrated Programs',
            id: 'MSP Arsenal & Integrated Programs',
            parent: 'Marketing, Sales & Pricing',
          },
        ],
      },
      {
        label: 'Operations',
        id: 'Operations',
        children: [
          {
            label: 'OPS AI/Gen AI',
            id: 'OPS AI/Gen AI',
            parent: 'Operations',
          },
          {
            label: 'Large Capital Project Management',
            id: 'Large Capital Project Management',
            parent: 'Operations',
          },
          {
            label: 'Research & Product Development',
            id: 'Research & Product Development',
            parent: 'Operations',
          },
          {
            label: 'Procurement',
            id: 'Procurement',
            parent: 'Operations',
          },
          {
            label: 'Manufacturing & Supply Chain Management',
            id: 'Manufacturing & Supply Chain Management',
            parent: 'Operations',
          },
          {
            label: 'Service & Support Operations',
            id: 'Service & Support Operations',
            parent: 'Operations',
          },
          {
            label: 'End to End Operations and Cost Excellence',
            id: 'End to End Operations and Cost Excellence',
            parent: 'Operations',
          },
        ],
      },
      {
        label: 'People & Organization',
        id: 'People & Organization',
        children: [
          {
            label: 'Client transformations/programs',
            id: 'Client transformations/programs',
            parent: 'People & Organization',
          },
          {
            label: 'POP Offers',
            id: 'POP Offers',
            parent: 'People & Organization',
          },
          {
            label: 'POP Capabilities',
            id: 'POP Capabilities',
            parent: 'People & Organization',
          },
        ],
      },
      {
        label: 'Risk and Compliance',
        id: 'Risk and Compliance',
        children: [
          {
            label: 'Compliance & Crisis Management',
            id: 'Compliance & Crisis Management',
            parent: 'Risk and Compliance',
          },
          {
            label: 'Risk Management',
            id: 'Risk Management',
            parent: 'Risk and Compliance',
          },
          {
            label: 'Cyber and Digital Risk',
            id: 'Cyber and Digital Risk',
            parent: 'Risk and Compliance',
          },
          {
            label: 'ESG Risk and Compliance',
            id: 'ESG Risk and Compliance',
            parent: 'Risk and Compliance',
          },
          {
            label: 'Risk and Compliance Tech and Analytics',
            id: 'Risk and Compliance Tech and Analytics',
            parent: 'Risk and Compliance',
          },
          {
            label: 'Risk Management per Industry',
            id: 'Risk Management per Industry',
            parent: 'Risk and Compliance',
          },
        ],
      },
      {
        label: 'Social Impact',
        id: 'Social Impact',
        children: [
          {
            label: 'Economic Development',
            id: 'Economic Development',
            parent: 'Social Impact',
          },
          {
            label: 'Food, Hunger and Agriculture',
            id: 'Food, Hunger and Agriculture',
            parent: 'Social Impact',
          },
          {
            label: 'Global Health',
            id: 'Global Health',
            parent: 'Social Impact',
          },
          {
            label: 'Climate Risk, Adaptation, and Resilience',
            id: 'Climate Risk, Adaptation, and Resilience',
            parent: 'Social Impact',
          },
          {
            label: 'Humanitarian Crisis',
            id: 'Humanitarian Crisis',
            parent: 'Social Impact',
          },
          {
            label: 'Diversity, Equity & Inclusion',
            id: 'Diversity, Equity & Inclusion',
            parent: 'Social Impact',
          },
          {
            label: 'Societal Impact Finance & Investing / Financial Inclusion',
            id: 'Societal Impact Finance & Investing / Financial Inclusion',
            parent: 'Social Impact',
          },
          {
            label: 'Socially Transformative Business',
            id: 'Socially Transformative Business',
            parent: 'Social Impact',
          },
          {
            label: 'Nonprofits & Other Social Impact',
            id: 'Nonprofits & Other Social Impact',
            parent: 'Social Impact',
          },
          {
            label: 'SI Inside',
            id: 'SI Inside',
            parent: 'Social Impact',
          },
          {
            label: 'Sustainable Business Model Innovation',
            id: 'Sustainable Business Model Innovation',
            parent: 'Social Impact',
          },
          {
            label: 'Just Transition',
            id: 'Just Transition',
            parent: 'Social Impact',
          },
          {
            label: 'Education',
            id: 'Education',
            parent: 'Social Impact',
          },
        ],
      },
      {
        label: 'Tech and Digital Advantage',
        id: 'Tech and Digital Advantage',
        children: [
          {
            label: '(Gen) AI and Digital Transformation',
            id: '(Gen) AI and Digital Transformation',
            parent: 'Tech and Digital Advantage',
          },
          {
            label: 'Tech Transformation and Innovation, Cyber',
            id: 'Tech Transformation and Innovation, Cyber',
            parent: 'Tech and Digital Advantage',
          },
          {
            label: 'Agile and Platform Operating Model',
            id: 'Agile and Platform Operating Model',
            parent: 'Tech and Digital Advantage',
          },
          {
            label: 'TDA in Industries',
            id: 'TDA in Industries',
            parent: 'Tech and Digital Advantage',
          },
          {
            label: 'Excellence in Support Functions (ESF)',
            id: 'Excellence in Support Functions (ESF)',
            parent: 'Tech and Digital Advantage',
          },
        ],
      },
    ],
  },
];

export const sortOptions = {
  type: 'sort',
  label: 'Sort By',
  id: 'orderBy',
  options: [
    { id: 'num_conversations', label: 'Most used', default: 'DESC' },
    { id: 'rating,ratings_count,num_conversations', label: 'Highest rated', default: 'desc_nulls_last,desc,desc' },
    { id: 'open_ai_created_at', label: 'Most recently created' },
  ],
};

import { Box } from '@mui/material';
import { useEffect } from 'react';
import { LayoutProps, useTheme } from 'react-admin';
import FloatingChatButton from '../components/FloatingChatButton';
import Header from '../components/Header';

const CustomLayout = ({ children, dashboard }: LayoutProps) => {
  document.title = 'Gallery | BCGPT Store';
  const [theme, setTheme] = useTheme();
  useEffect(() => {
    setTheme('dark');
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        backgroundColor: 'theme.palette.background.default',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Header />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          {/* <Sidebar>
          <Menu hasDashboard={!!dashboard} />
        </Sidebar> */}
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 2, p: 3 }}>
            {/* <div>hello</div> */}
            {children}
          </Box>
          <FloatingChatButton />
        </Box>
      </Box>
    </Box>
  );
};
export default CustomLayout;

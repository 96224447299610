import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ClipboardIcon from '@mui/icons-material/ContentPasteGo';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  LinearProgress,
  Link,
  Rating,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { SyntheticEvent, useState } from 'react';
import { useShowController } from 'react-admin';
import BookmarkButton, { notificationTypes } from '../components/BookmarkButton';
import { GPTType } from '../data-constants/data';
import PageDetailLayout from '../layouts/PageDetail';

export const DEFAULT_GPT_PROFILE_PICTURE_URL = '/openailogo-square.png';
const ORG_IDS = {
  OpenAI: 'org-OROoM5KiDq6bcfid37dQx4z4',
  BCG: 'org-aSPZI82En2kcvxzcsLqQhLem',
};

export const addDefaultSrc = (event: SyntheticEvent<HTMLImageElement, Event>) => {
  const target = event.target as HTMLImageElement;
  if (target.src) target.src = DEFAULT_GPT_PROFILE_PICTURE_URL;
};

const guessEmailAddress = (author: string) => {
  return encodeURIComponent(`${(author || '')?.split(' ').reverse().join('.')}@bcg.com`);
};
const GPTDetail = () => {
  const { record, isFetching, isLoading, error, data } = useShowController<GPTType>();

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState<{ text?: string; icon?: JSX.Element }>({});
  const [modalClipboardOpen, setModalClipboardOpen] = useState(false);
  const handleModalClose = () => setModalClipboardOpen(false);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotificationOpen(false);
  };
  // console.log({ record });
  const fireNotification = (text: string, icon: JSX.Element) => {
    setNotificationDetails({ text, icon });
    setNotificationOpen(true);
  };

  return (
    <PageDetailLayout>
      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        // message={notificationTypes[notificationText].text}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            {notificationDetails.icon}
            <Typography sx={{ ml: 2 }}>{notificationDetails.text}</Typography>
          </Box>
        }
        action={
          <Box>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        }
      ></Snackbar>
      {!record ? (
        'Loading...'
      ) : (
        <Grid container spacing={7} sx={{ justifyContent: 'start' }}>
          <Grid pl={0}>
            <Card sx={{ background: 'none', borderRadius: 4, boxShadow: 'none' }}>
              <CardMedia
                component="img"
                sx={{
                  maxHeight: 200,
                  maxWidth: 200,
                  borderRadius: 4,
                  ml: 'auto',
                  mr: { xs: 'auto', lg: '0 !important' },
                }}
                image={record.profile_picture_url || DEFAULT_GPT_PROFILE_PICTURE_URL}
                onError={addDefaultSrc}
                alt={record.display_name || ''}
              />
            </Card>
            <CardActions sx={{ justifyContent: { xs: 'center', md: 'end' } }}>
              <BookmarkButton
                GPTID={record.id}
                name={record.display_name || ''}
                notificationFunction={fireNotification}
              />
              <Tooltip title={notificationTypes['clipboard'].tooltip}>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText('https://chat.openai.com/g/' + (record.short_url || ''));
                    setModalClipboardOpen(true);
                  }}
                >
                  <ClipboardIcon />
                </IconButton>
              </Tooltip>
            </CardActions>
            <Dialog
              open={modalClipboardOpen}
              onClose={handleModalClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{ sx: { borderRadius: '25px', maxWidth: '500px' } }}
            >
              <Icon
                color="primary"
                sx={{
                  position: 'absolute',
                  left: 20,
                  top: 20,
                  width: '24px',
                  height: '24px',
                }}
              >
                <AssignmentOutlinedIcon />
              </Icon>

              <DialogTitle id="alert-dialog-title" sx={{ ml: 4 }} maxWidth="420px">
                Link copied to clipboard
              </DialogTitle>

              <DialogContent sx={{ ml: 4, mr: 3 }}>
                <DialogContentText id="alert-dialog-description" color="white">
                  You can now share this link with your team.
                </DialogContentText>
              </DialogContent>

              <IconButton
                color="primary"
                sx={{
                  position: 'absolute',
                  right: 20,
                  top: 20,
                  width: '24px',
                  height: '24px',
                }}
                onClick={handleModalClose}
              >
                <CloseIcon />
              </IconButton>
            </Dialog>
          </Grid>
          <Grid xs={12} lg={4}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bolder', fontSize: '22pt' }}>
              {record.display_name}
            </Typography>

            <Typography variant="h6" sx={{ mb: 2 }}>
              Engagement: {record.num_conversations} conversations
            </Typography>
            <LinearProgress
              sx={{ mb: 4, maxWidth: 500 }}
              variant="determinate"
              value={Math.min(((record.num_conversations || 0) * 100) / 300, 100)}
            />
            <Typography sx={{ mb: 4 }} paragraph>
              {record.description}
            </Typography>
            {record.organization_id === ORG_IDS.BCG && (
              <>
                <Box sx={{ display: 'flex', alignContent: 'center' }}>
                  {/* add icon from public/bcg-logo.svg with material ui icon */}
                  {/* <SvgIcon sx={{ fontSize: 20, ml: 1 }}>
                    <BcgLogoIcon />
                  </SvgIcon>{' '} */}
                  <Icon sx={{ mt: -0.5, mr: 1 }}>
                    <img src="/bcg-logo.svg" alt="BCG logo" style={{ width: 20, height: 20 }} />
                  </Icon>
                  <Typography sx={{ fontStyle: 'italic' }}>Created by BCG, for internal use</Typography>
                </Box>
                {record.author_display_name && (
                  <Tooltip title={`Send email to ${record.author_display_name}`}>
                    <Typography sx={{ mt: 1 }}>
                      Author:{' '}
                      <Link target="_blank" href={`mailto:${guessEmailAddress(record.author_display_name || '')}`}>
                        {record.author_display_name}
                      </Link>
                    </Typography>
                  </Tooltip>
                )}
              </>
            )}
            {record.organization_id === ORG_IDS.OpenAI && (
              <Box sx={{ display: 'flex', alignContent: 'center' }}>
                {/* add icon from public/bcg-logo.svg with material ui icon */}
                {/* <SvgIcon sx={{ fontSize: 20, ml: 1 }}>
                <BcgLogoIcon />
              </SvgIcon>{' '} */}
                <Icon sx={{ mt: -0.5, mr: 1 }}>
                  <img src="/openAI-logo.svg" alt="Open AI logo" style={{ width: 20, height: 20 }} />
                </Icon>
                <Typography sx={{ fontStyle: 'italic' }}>Created by Open AI for external use</Typography>
              </Box>
            )}
          </Grid>
          <Grid xs={12} lg={4}>
            <Box sx={{ mb: 4 }}>
              <Typography sx={{ fontWeight: 'bolder' }}>Sample prompts:</Typography>
              {(record.prompt_starters || []).length > 0 ? (
                (record.prompt_starters || []).map((prompt, index) => (
                  <Typography key={index} sx={{ fontStyle: 'italic', color: '#3EAD92' }}>
                    - {prompt}
                  </Typography>
                ))
              ) : (
                <Typography sx={{ fontStyle: 'italic' }}>No sample prompts provided.</Typography>
              )}
            </Box>
            <Box sx={{ mb: 4 }}>
              {/* Open AI Categories */}
              {(
                [
                  // Open AI Categories
                  ...(record?.categories || []),
                  // BCG assigned  Categories
                  ...(record?.bcg_added_categories_use_cases || []),
                  // BCG assigned Cohorts
                  ...(record?.bcg_added_cohorts || []),
                  // BCG assigned Industries
                  ...(record?.bcg_added_industries || []),
                  // BCG assigned Functional Practice Areas
                  ...(record?.bcg_added_functional_practice_areas || []),
                ] || []
              )?.map((industry) => (
                <Chip
                  key={industry}
                  color="primary"
                  sx={{ textTransform: 'capitalize', mr: 1, mb: 1 }}
                  label={industry}
                />
              ))}
            </Box>
            <Box sx={{}}>
              {record.rating && (
                <Tooltip
                  title={`${record.ratings_count || 0} ratings: ${Math.round((record.rating || 0) * 10) / 10} stars`}
                >
                  <Box sx={{ display: 'flex', alignContent: 'center' }}>
                    Rating:{' '}
                    <Rating
                      precision={0.25}
                      value={record.rating || 0}
                      readOnly
                      sx={{
                        ml: 2,
                        '& .MuiRating-iconFilled': {
                          color: '#ff6d75 ',
                        },
                        '& .MuiRating-iconHover': {
                          color: '#ff3d47 ',
                        },
                      }}
                    />{' '}
                  </Box>
                </Tooltip>
              )}
              {/* Replace `value={4}` with dynamic rating if available */}
            </Box>
            <Button
              fullWidth
              variant="contained"
              href={'https://chat.openai.com/g/' + (record.short_url || '')}
              target="_blank"
              sx={{ borderRadius: 90, mt: 4, maxWidth: 500 }}
            >
              Launch App
            </Button>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="h6">Related Apps</Typography>
            <AppCards relatedApps={[]} /> Replace with actual related apps 
            <Typography sx={{ fontStyle: 'italic' }}> None found...</Typography>
          </Grid> */}
        </Grid>
      )}
    </PageDetailLayout>
  );
};

export default GPTDetail;

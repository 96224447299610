import SearchIcon from '@mui/icons-material/Search';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Icon,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Link, useStore } from 'react-admin';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom

const LINK_TO_CREATE_GPTS = 'https://chat.openai.com/gpts/editor';
export const GLOBAL_SEARCH_STORE_NAME = 'GlobalSearchInput';
const Header = () => {
  const [globalSearchInput, setGlobalSearchInput] = useStore<string>(GLOBAL_SEARCH_STORE_NAME, '');
  const location = useLocation(); // Get the current location

  const [modalOpen, setModalOpen] = useState(false);
  // Check if the route path is "/faq"
  const isFaqRoute = location.pathname.toLowerCase() === '/faq';

  return (
    <>
      <AppBar
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{ pt: 4, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" sx={{ width: '100%' }}>
            <Link to="/" sx={{ color: 'white' }}>
              <Box>
                {/* <img src="/bcgpt-store-logo.svg" alt="bcg logo" /> */}
                <Typography variant="h4" color="inherit" noWrap sx={{ fontWeight: 'bolder', mx: 2, display: 'inline' }}>
                  BCG GPT Store |
                </Typography>{' '}
                <img
                  src="/amplify-impact-logo-transparent.png"
                  alt="bcg logo"
                  style={{ height: 40, marginBottom: -10 }}
                />
              </Box>
            </Link>
            <Box sx={{ position: 'relative', mx: 4 }}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Search"
                value={globalSearchInput}
                InputProps={{
                  sx: { borderRadius: 100 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: 'white' }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  margin: '8px 0', // Add some space if needed
                }}
                onChange={(event) => {
                  setGlobalSearchInput(event.target.value);
                }}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: { xs: 'center', md: 'end' } }}
            >
              <Link
                to="/faq"
                sx={{
                  color: 'white',
                  fontWeight: 'bolder',
                  mr: 4,
                  borderBottom: isFaqRoute ? 'solid 1px white' : 'none',
                }}
              >
                FAQ
              </Link>

              <Link
                to="https://chat.openai.com/gpts/mine"
                sx={{ color: 'white', fontWeight: 'bolder', mr: 4 }}
                target="_blank"
              >
                My GPTs
              </Link>
              <Button
                color="primary"
                variant="contained"
                onClick={(event) => {
                  setModalOpen(true);
                  event.preventDefault();
                }}
                sx={{ fontWeight: 'bolder', textWrap: 'none', wordWrap: 'none' }}
                href={LINK_TO_CREATE_GPTS}
                target="_blank"
              >
                + Create
              </Button>
            </Box>
          </Box>
        </Toolbar>

        <Divider
          sx={{
            mt: 4,
          }}
        />
        <Dialog
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ sx: { borderRadius: '25px', maxWidth: '500px' } }}
        >
          <Icon
            color="primary"
            sx={{
              position: 'absolute',
              left: 20,
              top: 20,
              width: '24px',
              height: '24px',
            }}
          >
            <WarningAmberIcon />
          </Icon>
          <DialogTitle id="alert-dialog-title" sx={{ ml: 4 }}>
            GPT Creation Privacy Policy
          </DialogTitle>
          <DialogContent sx={{ ml: 4 }}>
            <DialogContentText id="alert-dialog-description" color="white">
              Be careful! New GPTs start private, but you will have the option to share a custom GPT to all BCGers; only
              do this with sanitized data and materials.
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" color="primary" sx={{ mt: 2 }}>
              You will not be able to share any custom GPTs to people outside of BCG.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button
              onClick={() => {
                setModalOpen(false);
              }}
              sx={{ borderRadius: '50px', color: 'grey' }}
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              href={LINK_TO_CREATE_GPTS}
              target="_blank"
              onClick={() => {
                setModalOpen(false);
              }}
              autoFocus
              variant="contained"
              color="primary"
              sx={{ borderRadius: '50px' }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </AppBar>
      {/* <FloatingChatButton /> */}
    </>
  );
};

export default Header;
